import { useMutation } from '@apollo/client/index.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import {
  EditTimeEntryDialog_Load,
  EditTimeEntryDialog_Save,
} from '@asaprint/asap/components/dialogs/EditTimeEntryDialog.graphql';
import TimeEntryForm, { FormValues, validate } from '@asaprint/asap/components/forms/TimeEntryForm.js';
import {
  EditTimeEntryDialog_LoadQuery,
  EditTimeEntryDialog_LoadQueryVariables,
  EditTimeEntryDialog_SaveMutation,
  EditTimeEntryDialog_SaveMutationVariables,
} from '@asaprint/asap/schema.client.types.js';
import { toOption as orderReceivedToOption } from '@asaprint/common/helpers/OrderReceived.js';
import { toOption as phaseToOption } from '@asaprint/common/helpers/Phase.js';
import { toOption as productToOption } from '@asaprint/common/helpers/Product.js';
import { toOption as userToOption } from '@asaprint/common/helpers/User.js';
import { ConnectedDialogSubmitButtons } from '@engined/client/components/dialogs/DialogSubmitButtons.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import Loading from '@engined/client/components/Loading.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { getLogger } from '@engined/core/services/logger.js';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';

const logger = getLogger('components/modals/EditTimeEntryDialog');

interface OwnProps {
  open?: boolean;
  timeEntryId: string;
  refetchQueries: string[];

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

type Props = OwnProps;

const EditTimeEntryDialog: React.FunctionComponent<Props> = ({ open, onClose, timeEntryId, refetchQueries }) => {
  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
      <EditTimeEntryDialogContent onClose={onClose} timeEntryId={timeEntryId} refetchQueries={refetchQueries} />
    </Dialog>
  );
};

EditTimeEntryDialog.displayName = 'EditTimeEntryDialog';

export default EditTimeEntryDialog;

interface EditTimeEntryDialogContentOwnProps {
  timeEntryId: string;
  refetchQueries: string[];

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

const EditTimeEntryDialogContent: React.FunctionComponent<EditTimeEntryDialogContentOwnProps> = ({
  timeEntryId,
  onClose,
  refetchQueries,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading, error } = useQuery<EditTimeEntryDialog_LoadQuery, EditTimeEntryDialog_LoadQueryVariables>(
    EditTimeEntryDialog_Load,
    {
      variables: {
        id: timeEntryId,
      },
      skip: !timeEntryId,
    },
  );
  const [saveExecute] = useMutation<EditTimeEntryDialog_SaveMutation, EditTimeEntryDialog_SaveMutationVariables>(
    EditTimeEntryDialog_Save,
    {
      refetchQueries,
    },
  );

  const onSubmit: OnSubmit<FormValues> = async ({
    product,
    phase,
    startAt,
    endAt,
    type,
    orderReceived,
    orderReceivedPhase,
    user,
    ...values
  }: FormValues) => {
    try {
      const response = await saveExecute({
        variables: {
          input: {
            ...values,
            product: type === 'product' ? product?.value ?? null : null,
            orderReceived: type === 'orderReceived' ? orderReceived?.value ?? null : null,
            orderReceivedPhase: type === 'orderReceived' ? orderReceivedPhase ?? null : null,
            phase: type === 'product' ? phase?.value ?? null : null,
            user: user?.value ?? null,
            startAt: startAt?.toISOString() ?? null,
            endAt: endAt?.toISOString() ?? null,
          },
        },
      });
      enqueueSnackbar('Úspešne ste uložili strávený čas.', {
        variant: 'success',
      });
      onClose();
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  const timeEntry = data?.timeEntry;
  const initialValues = useMemo(
    (): FormValues =>
      timeEntry
        ? {
            id: timeEntry.id,
            updatedAt: timeEntry.updatedAt,
            startAt: timeEntry.startAt ? new Date(timeEntry.startAt) : null,
            endAt: timeEntry.endAt ? new Date(timeEntry.endAt) : null,
            phase: timeEntry.phase ? phaseToOption(timeEntry.phase) : null,
            product: timeEntry.product ? productToOption(timeEntry.product) : null,
            orderReceived: timeEntry.orderReceived ? orderReceivedToOption(timeEntry.orderReceived) : null,
            orderReceivedPhase: timeEntry.orderReceivedPhase ?? null,
            type: timeEntry.product ? 'product' : timeEntry.orderReceived ? 'orderReceived' : 'none',
            note: timeEntry.note ?? '',
            user: userToOption(timeEntry.user),
          }
        : null,
    [timeEntry],
  );

  if (error || (loading && !data)) {
    return <Loading error={error} />;
  }

  return (
    <Form defaultValues={initialValues} onSubmit={onSubmit} validate={validate}>
      <DialogTitle>Úprava záznamu</DialogTitle>
      <DialogContent>
        <TimeEntryForm />
      </DialogContent>
      <ConnectedDialogSubmitButtons closeOnClick={() => onClose()} submitLabel="Upraviť čas" />
    </Form>
  );
};

EditTimeEntryDialogContent.displayName = 'EditTimeEntryDialogContent';
